import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import "../../stylesheets/life.scss";
import Pageheader from "../../components/pageheader";


const BlogPosts = ({ data }) => {
  const blogPosts = data.allContentfulLife.edges;

  const { heroImage, title, body } = data.contentfulPageHeader;


  const headerTitle = data.contentfulPageHeader.title;
  const headerDesc = data.contentfulPageHeader.body.body;
 const headerImage = data.contentfulPageHeader.heroImage.fluid.src;
 

  return (

    <Layout>
      <SEO title={headerTitle} pageDescription={headerDesc} heroImage={headerImage} keywords={[`gatsby`, `application`, `react`]} />
      <Pageheader title={title} image={heroImage} body={body} />
      <div className="container">
        <div className="row">
          {blogPosts.map(({ node: post }) => (
            <div key={post.id} className="col-md-6 col-12 blogposts">
              <Link to={`/about/life/${post.slug}`}>
                <img src={post.heroImage.fixed.src} className="img-fluid" alt={post.heroImage.title} />
              </Link>
              <Link to={`/about/life/${post.slug}`}><h2 className="blog-title">
                {post.title}
              </h2>
              </Link>
              <p className="date">{post.createdAt}</p>
              <div className="description">
                {post.description.content[0].content[0].value}
                
              </div>
              <p className="readmore"><Link to={`/about/life/${post.slug}`}>read more</Link></p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default BlogPosts;
export const query = graphql`
  query BlogPostsPageQuery {
    allContentfulLife(limit: 1000, sort: {order: DESC, fields: createdAt}) {
            edges {
        node {
          id
          title
          slug
          createdAt(formatString: "MMMM DD, YYYY")
          description {
            content {
              content {
                value
              }
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          category {
          blogpostCategoryName
        }
          heroImage {
            fixed(width: 960, height: 600) {
              src
            }
            
            title
          }
          tags
        }
      }
    }
    contentfulPageHeader(title: {eq: "life"}) {
      heroImage {
        fixed(width: 2000, height: 500) {
          src
        }
        fluid(maxWidth: 2000, quality: 100) {
          src
        }
        title
      }
      title
      body {
        body
      }
    }
  }

`;
